import uniqueId from "../../lib/unique_id";
import * as requests from "./board_requests"

var board;
var base = fabric.Canvas.prototype;
var currentRuler, currentRulerText, currentX, currentY, currentBackendX, currentBackendY;

$( document ).on('board:created', function(event, b) { board = b });

export const updateRulerForZoom = function(object) {
  if (!object.ruler) { return }
  if(object.text != null) {
    object.fontSize = Math.round(23 / board.getZoom());
  }
  if(object.type == 'line') {
    object.strokeWidth = Math.round(3 / board.getZoom());
  }
} 

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const snapToGrid = function(coordinate) {
  return  Math.floor(coordinate / board.blockSize) * board.blockSize + (board.blockSize / 2);
}

const updateRuler = function(x, y)  {
  if(board.rulerSnapToGrid) {
    x = snapToGrid(x);
    y = snapToGrid(y);
  }
  x = clamp(x, 0, board.mapWidth);
  y = clamp(y, 0, board.mapHeight);

  currentRuler.set({ x2 : x, y2 : y });
  currentX = x;
  currentY = y;
  if(board.rulerShowOthers) {
    updateRulerForOtherPlayers(x,y);
  }
}

const updateRulerText = function() {
  var pixelDistance;
  var distanceX = currentRuler.x2 - currentRuler.x1;
  var distanceY = currentRuler.y2 - currentRuler.y1;
  if(board.rulerDiagonalsAsFiveFeet) {
    pixelDistance = Math.max(Math.abs(distanceX), Math.abs(distanceY));
  } else {
    pixelDistance = Math.hypot(distanceX, distanceY);
  }
  var feetDistance = Math.round(pixelDistance * board.feetPerPixel);
  var textAngle = Math.atan2(distanceY, distanceX) + (Math.PI / 4);
  var textX = currentRuler.x1 + (distanceX / 2) + (30 * Math.cos(textAngle));
  var textY = currentRuler.y1 + (distanceY / 2) + (30 * Math.sin(textAngle));

  currentRulerText.set({'text': ` ${feetDistance.toString()} ft `, 'left': textX, 'top': textY });
}

const handleMouseMoveForRuler = function(options) {
  if(!board.isRulerMode) { return }
  if(currentRuler == null) { return }
  if(board.contextualMenuOpen) { return }

  updateRuler(options.absolutePointer.x, options.absolutePointer.y);
  updateRulerText();
  board.renderAll();
}

const newRulerText = function() {
  const averageMapDimension = (board.mapWidth + board.mapHeight) / 2;
  return new fabric.Text('', { 
    originX: 'center',
    originY: 'center',
    stroke: board.rulerColor,
    fill: board.rulerColor,
    fontSize: Math.round(23 / board.getZoom()),
    fontFamily: 'arial',
    backgroundColor: 'white',
    ruler: true,
    uid: uniqueId(),
    doNotPersistInDatabase: true
  });
}

const newRuler = function(startX, startY) {
  if(board.rulerSnapToGrid) {
    startX = snapToGrid(startX);
    startY = snapToGrid(startY);
  }
  const averageMapDimension = (board.mapWidth + board.mapHeight) / 2;
  return new fabric.Line([startX, startY, startX, startY], {
    fill: board.rulerColor,
    stroke: board.rulerColor,
    strokeWidth: Math.round(3 / board.getZoom()),
    ruler: true,
    selectable: false,
    uid: uniqueId(),
    doNotPersistInDatabase: true,
  });
}

const insideMap = function(x,y) {
  return x >= 0 && x <= board.mapWidth && y >= 0 && y <= board.mapHeight
}

const updateRulerForOtherPlayers = function(x, y) {
  if(!board.rulerShowOthers) { return }
  setTimeout(function() {
    // only update backend if we're on the same location after a period of time 
    if(x == currentX && y == currentY && (currentBackendX != currentX || currentBackendY != currentY) && currentRuler != null && currentRulerText != null) {
      currentBackendX = currentX;
      currentBackendY = currentY;
      const a = uniqueId();
      requests.updateRequest(currentRuler);
      requests.updateRequest(currentRulerText);
    }
  }, 200)
}


base.handleMouseDownForRuler = function(options) {
  if(!board.isRulerMode) { return }
  if(!insideMap(options.absolutePointer.x, options.absolutePointer.y)) { return }

  currentRuler = newRuler(options.absolutePointer.x, options.absolutePointer.y);
  currentRulerText = newRulerText();
  if(board.rulerShowOthers) {
    requests.createRequest(currentRuler)
    requests.createRequest(currentRulerText)
  }
  board.addObject(currentRulerText);
  board.addObject(currentRuler);
  board.on('mouse:move', handleMouseMoveForRuler);
}

base.handleRulerOnMouseUp = function(options) {
  if(!board.isRulerMode) { return }
  
  board.removeObject(currentRuler);
  board.removeObject(currentRulerText);
  if(board.rulerShowOthers) {
    requests.destroyUnpersistedRequest(currentRuler.uid);
    requests.destroyUnpersistedRequest(currentRulerText.uid);
  }
  currentRulerText = null;
  currentRuler = null;
  board.off('mouse:move', handleMouseMoveForRuler);

  board.mouseUpPerformed = true;
}