import * as requests from "./board_requests"
import uniqueId from "../../lib/unique_id"
import isMobile from "../../lib/is_mobile"
import { resetConditionIndicatorObjects, resetCombatantIndicatorObject, removeCombatIndicatorObjects } from "./board_indicators";
import isRightClick from "../right_click";
import { openMenu, setOriginalOptionsForContextualMenu } from "./board_contextual_menu";
import { setObjectOpacityAndSelectability } from "./board_object_opacity_and_selectability";
import { updateCursorIcon } from "../board_cursor";
import { addLoadingIcon } from "./board_place_token_and_image";
import { resetDoorObject } from "./board_doors";
import { updateRulerForZoom } from "./board_ruler";

var canvas;
var base = fabric.Canvas.prototype;

base.redrawGrid = function() {
  var grid = canvas.findObject('grid')
  if(grid != null) {
    canvas.remove(grid);
  }
  if(canvas.displayGrid ) {
    var gridGroup = new fabric.Group([], {left: 0, top: 0, width: canvas.mapWidth, height: canvas.mapHeight });
    var gridLines = [];
    const lineProperties = { type: 'line', stroke: canvas.gridColor, selectable: false, evented: false, objectCaching: false }
    for (var i = 0; i < (parseInt(canvas.mapHeight) / canvas.blockSize); i++) {
      gridLines.push(new fabric.Line([ 0, i * canvas.blockSize, canvas.mapWidth, i * canvas.blockSize], lineProperties))
    }
    for (var i = 0; i < (parseInt(canvas.mapWidth) / canvas.blockSize); i++) {
      gridLines.push(new fabric.Line([ i * canvas.blockSize, 0, i * canvas.blockSize, canvas.mapHeight], lineProperties));
    }

    var gridGroup = new fabric.Group(gridLines, { left: 0, top: 0, selectable: false, evented: false, objectCaching: false});
    gridGroup.uid = 'grid';
    canvas.addObject(gridGroup);
  }
}

base.refreshGrid = function() {
  canvas.redrawGrid();

  //zoom to fill main area
  const extraSpaceFactor = 0.85;
  const widthFactor = (canvas.main.outerWidth() * extraSpaceFactor) / canvas.mapWidth;
  const heightFactor = (canvas.main.outerHeight() * extraSpaceFactor) / canvas.mapHeight;
  canvas.usefulZoom(Math.min(widthFactor, heightFactor));

  //scroll to center
  var viewport = canvas.viewportTransform;
  viewport[4] = (canvas.main.outerWidth() - (canvas.mapWidth * canvas.getZoom())) / 2;
  viewport[5] = (canvas.main.outerHeight() - (canvas.mapHeight * canvas.getZoom())) / 2;
  canvas.setViewportTransform(canvas.viewportTransform);
  canvas.requestRenderAll();

}

base.findObject = function(uid) {
  return canvas.getObjects().find(function (object) { 
    return object.uid == uid; 
  });
}

base.resizeToParent = function() {
  // if on mobile, make canvas the whole screensize, otherwise, just the main area
  if (isMobile()) {
    canvas.setWidth($(window).width());
    canvas.setHeight($(window).height());    
  } else {
    canvas.setWidth(canvas.main.width());
    canvas.setHeight(canvas.main.height());  
  }
}

base.removeObject = function(object) {
  if(object == null) { return }
  canvas.remove(object);
  if(object.indicatorObjects != null) {
    object.indicatorObjects.forEach( indicator => canvas.remove(indicator));
  }
  if(object.combatIndicatorObject != null) {
    removeCombatIndicatorObjects(object, canvas);
  }
  if(object.doorObject != null) {
    canvas.remove(object.doorObject);
  }
  if(object.lockObject != null) {
    canvas.remove(object.lockObject);
  }
  if(object.lockBackgroundObject != null) {
    canvas.remove(object.lockBackgroundObject);
  }

  canvas.updateVisibility();
}

base.urlForObject = function(object) {
  return `/campaigns/${canvas.campaignId}/board_objects/${object.uid}`;
}

const addToken = function(newObjectJSON, imageFinishedCallback) {
  fabric.util.loadImage(newObjectJSON.image_url, function(img) {
    if(img == null) { 
      Rollbar.error(`Failed to load an image for the token: ${newObjectJSON?.uid}`) 
      return 
    }
    const radius = img.height / 2;
    var token = new fabric.Circle({ radius: radius });

    token.set(newObjectJSON);
    token.set('fill', new fabric.Pattern({ source: img, repeat: 'no-repeat' }));
    token.scaleToHeight(newObjectJSON.scaleHeight);
    token.scaleToWidth(newObjectJSON.scaleWidth);
    token.hasBorders = newObjectJSON.hasBorders;
    token.hasControls = newObjectJSON.hasControls;

    canvas.addObject(token);
    requests.updateRequest(token);

    // add all indicators
    resetConditionIndicatorObjects(token);
    resetCombatantIndicatorObject(token);
 
    // Highlight token if we're adding it and it's the selected character
    if (token.uid == canvas.selectedCharacterId) {
      canvas.highlightToken(token);
    }
    // if we're adding a token that is supposed to be selected, updated the visibility
    if(token.uid == canvas.selectedCharacterId) { canvas.updateVisibility() } 

    if(imageFinishedCallback != null) { imageFinishedCallback() }
  });
}
base.addObject = function(newObject) {
  newObject.set({hoverCursor: 'none', moveCursor: 'none'});
  setObjectOpacityAndSelectability(newObject, canvas);
  canvas.add(newObject);
}

base.addBackendObject = function(newObjectJSON, imageFinishedCallback) {
  if (canvas.findObject(newObjectJSON.uid) != null) return;

  var newObject;
  if(newObjectJSON.type == "rect") {
    newObject = new fabric.Rect(newObjectJSON);
    canvas.addObject(newObject);
  } else if (newObjectJSON.type == "path" && newObjectJSON.shapeType == null ) {
    newObject = new fabric.Path(newObjectJSON.path);
    newObject.fill = null // this is needed because we're not saving fill on the backend and fabric will default to black unless set to null
    newObject.set(newObjectJSON);
    canvas.addObject(newObject);
  } else if (newObjectJSON.type == "circle") {
    if (newObjectJSON.token) {
      addToken(newObjectJSON, imageFinishedCallback)
    } else if (newObjectJSON.wall != null) {
      newObject = new fabric.Circle(newObjectJSON);
      newObject.fill = 'red';
      canvas.addWallPointListeners(newObject);    
      canvas.addObject(newObject);
    } else if (newObjectJSON.lightSource != null) {
      newObject = new fabric.Circle(newObjectJSON);
      newObject.fill = 'blue';
      canvas.addLightSourceListeners(newObject); 
      canvas.addObject(newObject)
    }
  } else if (newObjectJSON.type == "line") {
    newObject = new fabric.Line([newObjectJSON.x1, newObjectJSON.y1, newObjectJSON.x2, newObjectJSON.y2], newObjectJSON);
    if(newObject.ruler) { updateRulerForZoom(newObject) }
    canvas.addObject(newObject); 
    if(newObject.wall == true) {
      resetDoorObject(newObject);
    }
  } else if (newObjectJSON.type == "image") {
    fabric.Image.fromURL(newObjectJSON.image_url, function(imageObject) {
      imageObject.set(newObjectJSON);
      if(newObjectJSON.scaleWidth != null && newObjectJSON.scaleHeight != null) {
        imageObject.set({scaleX: newObjectJSON.scaleWidth / imageObject.width, scaleY: newObjectJSON.scaleHeight / imageObject.height});
      }
      canvas.addObject(imageObject);
    });
  } else if (newObjectJSON.type == 'pin') {
    newObject = canvas.convertServerPinToClient(newObjectJSON, canvas.currentUserIsGm);
    canvas.addObject(newObject);
  } else if (newObjectJSON.shapeType != null) {
    const svgElement = document.getElementById(`${newObjectJSON.shapeType}-path`).getElementsByTagName('path')[0];
    fabric.Path.fromElement(svgElement, function(shapeObject) {
      shapeObject.set(newObjectJSON);
      shapeObject.scaleX = newObjectJSON.scaleWidth / shapeObject.width;
      shapeObject.scaleY = newObjectJSON.scaleHeight / shapeObject.height;
      canvas.addObject(shapeObject);
    });
  } else if (newObjectJSON.text != null) {
    newObject = new fabric.Text(newObjectJSON.text, newObjectJSON);
    if(newObject.ruler) { updateRulerForZoom(newObject) }
    canvas.addObject(newObject);
  }

  // For some reason these have to be manually set
  if(newObject != null) {
    newObject.hasBorders = newObjectJSON.hasBorders;
    newObject.hasControls = newObjectJSON.hasControls;

    // Remove any temp objects
    if (newObject.temporary != null) {
      canvas.fade_remove_in(newObject, canvas.highlightTimer);
    }
  }
  return newObject;
}

base.loadCurrentBoard = function() {
  $('#board-loading-icon').show();
  $('.board-wrapper').hide();
  canvas.clear();
  canvas.darknessLayer = null;
  canvas.dimnessLayer = null;
  
  // Add timestamp to prevent caching
  $.get( `${canvas.campaignId}/current_board?${new Date().getTime()}`, function( board ) {

    canvas.main.off('scroll');
    if(board == null) { 
      canvas.main.scrollTop(0);
      canvas.main.scrollLeft(0);
      canvas.overview = true;
      $('#board-loading-icon').hide();
      return
    } 

    canvas.overview = false;
    canvas.mapHeight = board.height;
    canvas.mapWidth = board.width;
    canvas.restrictVisibility = board.restrict_visibility;
    canvas.blockSize = board.block_size;
    canvas.wallBlockSize = board.wall_block_size;
    canvas.gridColor = board.grid_color;
    canvas.verticalWalls = board.vertical_walls;
    canvas.horizontalWalls = board.horizontal_walls;
    canvas.lighting = board.lighting;
    canvas.currentUserIsGm = board.current_user_is_gm;
    canvas.selectedCharacterId = board.selected_character_id;
    canvas.selectableCharacterIds = board.selectable_character_ids;
    canvas.feetPerPixel = board.feet_per_pixel;
    canvas.brightLightRadiusDefault = board.bright_light_radius_default;
    canvas.dimLightRadiusDefault = board.dim_light_radius_default;
    canvas.pinSizeDefault = board.pin_size_default;
    canvas.pinColorDefault = board.pin_color_default;
    canvas.pinShowPlayersDefault = board.pin_show_players_default;
    canvas.doorColorDefault = board.door_color_default;
    canvas.doorSizeDefault = board.door_size_default;
    canvas.doorOpenDirectionDefault = board.door_open_direction_default;
    canvas.doorPivotSideDefault = board.door_pivot_side_default;
    canvas.doorStateDefault = board.door_state_default;
    canvas.boardId = board.id;
    canvas.displayGrid = board.display_grid;
    canvas.tokenStackingOrder = board.token_stacking_order;
    canvas.imageStackingOrder = board.image_stacking_order;
    canvas.rulerSnapToGrid = board.ruler_snap_to_grid;
    canvas.rulerDiagonalsAsFiveFeet = board.ruler_diagonals_as_five_feet;
    canvas.rulerColor = board.ruler_color;
    canvas.rulerShowOthers = board.ruler_show_others;
    canvas.sessionId = uniqueId();  // we set this so that when we make an update from a client, we can skip that same update when it comes back from the server
    canvas.useWorkersForVisibility = true; // we assume we're going to use workers, and if the calculations are fast, we stop using workers

    canvas.updateDebugWalls()

    const tokenCount = board.objects.filter(x => x.token == true).length;
    var finishedTokenCount = 0;
    const tokenFinished = function(object) {
      finishedTokenCount = finishedTokenCount + 1;
      if(finishedTokenCount < tokenCount)  { return }

      canvas.updateVisibility();
    }
    board.objects.forEach(object => canvas.addBackendObject(object, tokenFinished));

    $('#board-loading-icon').hide();
    $('.board-wrapper').show();
    // scroll setup has be done after the wrapper is shown 
    canvas.main.scrollTop(100);
    canvas.main.scrollLeft(100);
    canvas.main.on('scroll', function() {
      window.__board.usefulPan(100 - canvas.main.scrollLeft(), 100 - canvas.main.scrollTop())
      canvas.main.scrollTop(100);
      canvas.main.scrollLeft(100);
    })

    $( document ).trigger('board_id:set', board);
    canvas.refreshGrid();
    canvas.updateVisibility();  // not sure why this is necessary but it is
    updateCursorIcon();

  });
}


const canPerformMouseUp = function() {
  return !canvas.isDrawingMode && !canvas.isPinching && !canvas.isDragging && !canvas.isDraggingObject && !canvas.mouseUpPerformed
}
const checkForSelectedTargetOnMouseUp = function(options) {
  const object = options.target;
  if(object == null) return;
  if(!object.token) return;
  const attackerIdElement = $('.selecting-target:visible');
  if(attackerIdElement.length == 0) return;
  const attackerId = attackerIdElement.data('attackerId');
  const actionId = attackerIdElement.data('actionId');
  const addingOnMobile = attackerIdElement.data('addingOnMobile');
  if(attackerId == null || attackerId == "") return;
  if(actionId == null || actionId == "") return;
  if(attackerId == object.uid) return;

  canvas.mouseUpPerformed = true;
  requests.addCharacterTarget(attackerId, actionId, object.uid, addingOnMobile);
}

const handleMouseDown = function(options){
  if(isRightClick(options.e)) { 
    setOriginalOptionsForContextualMenu(options)
    openMenu(options);
  } else {
    // set a unique id for every mouse click
    canvas.currentClickId = uniqueId();
    canvas.maintainLastUndo = false;
    // if there is a token selected assume any click is a deselect (exception: if a player clicks a player clickable object (like a door), dont deselect the token)
    if(canvas.currentUserIsGm && canvas.selectedCharacterId != null && (options.target == null || (options.target.token != true && options.target.playerClickable != true)) ) {
      requests.deselectCharacter();
    }
    canvas.handleMouseDownForTokenDrag(options);
    canvas.handleMouseDownForScrollDrag(options);
    canvas.handleMouseDownForRuler(options);
    canvas.handleMouseDownForContextualMenu(options);
  }
}

const handleObjectMoving = function(options) {
  canvas.handleTokenDrag(options);
}

const handleMouseMove = function(options) {
  if (!canvas.contextualMenuOpen) { canvas.handleScrollDrag(options) };
  canvas.handleMouseMoveForContextualMenu(options);
}

var usingPointer;
const handleMouseOver = function(options) {
  if(options.target == null) { return }
  if(options.target.playerClickable == true && canvas.isErasingMode != true) {
    updateCursorIcon('pointer');
    usingPointer = true
  }
}

const handleMouseOut = function(options) {
  if (usingPointer != true) { return }
  updateCursorIcon();
}

const handleMouseUp = function(options) {
  if (canPerformMouseUp()) { canvas.handleMouseMoveForContextualMenu(options); };
  if (canPerformMouseUp()) { canvas.handleClickingDoorOnMouseUp(options) }
  if (canPerformMouseUp()) { canvas.handleRulerOnMouseUp(options) };
  if (canPerformMouseUp()) { canvas.handlePlacingCharacterImageOrShapeOnMouseUp(options) };
  if (canPerformMouseUp()) { checkForSelectedTargetOnMouseUp(options) };
  if (canPerformMouseUp()) { canvas.handleWallClickOnMouseUp(options) };
  if (canPerformMouseUp()) { canvas.handleAddLightOnMouseUp(options) };
  if (canPerformMouseUp()) { canvas.handleAddPinOnMouseUp(options) };
  if (canPerformMouseUp()) { canvas.handleEraseOnMouseUp(options) };
  if (canPerformMouseUp()) { canvas.handleClickingCombatants(options) };
  if (canPerformMouseUp()) { canvas.selectCharacterOnMouseInteraction(options) }
  if (canPerformMouseUp()) { canvas.handleDoubleTapOnMouseUp(options) };

  canvas.isPinching = false;
  canvas.isDragging = false;
  canvas.isDraggingObject = false;
  canvas.startingDrag = false;
  canvas.mouseUpPerformed = false;
  canvas.currentClickId = null;
  if(canvas.maintainLastUndo != true) {
    canvas.undoClickId = null;
    canvas.undoText = null;
  }
}

const detectTrackPad = function(e) {
  var isMouseWheel = true;
  isMouseWheel = Math.abs(e.deltaX) % 1 > 0 || Math.abs(e.deltaY) % 1 > 0;
  return !isMouseWheel
}

const handleMouseWheel = function(options) {
  var event = options.e;
  if (detectTrackPad(event)) { return }
  var wheelDelta = event.deltaY;
  var zoom = canvas.getZoom();
  zoom *= 0.999 ** wheelDelta;
  canvas.usefulZoom(zoom, options.pointer);
  event.preventDefault();
  event.stopPropagation();
}


const handleBoardNewFileDrop = function(options) {
  const files = options.e.dataTransfer.files;
  if(files.length == 0) { return }
  const canvasPointer = canvas.getPointer(options.e);
  const loadingIconUuid = addLoadingIcon(canvasPointer.x, canvasPointer.y);
  requests.placeImagesFromFiles(files, canvasPointer.x, canvasPointer.y, loadingIconUuid);
  options.e.preventDefault();
}

const handleBoardKnownObjectDrop = function(options) {
  const id = options.e.dataTransfer.getData('id');
  const dragType = options.e.dataTransfer.getData('dragType');
  if(id == null || id == "") { return }
  if(dragType == null || dragType == "") { return }
  const canvasPointer = canvas.getPointer(options.e);
  const loadingIconUuid = addLoadingIcon(canvasPointer.x, canvasPointer.y);
  if(dragType == 'character') {
    requests.placeCharacter(id, canvasPointer.x, canvasPointer.y, loadingIconUuid);
  } else if (dragType == 'image') {
    const mainDiv = document.getElementById('grid');
    mainDiv.classList.remove('file-dragging');
    requests.placeImageFromBoardImage(id, canvasPointer.x, canvasPointer.y, loadingIconUuid);
  } else if (dragType == 'shape') {
    const mainDiv = document.getElementById('grid');
    mainDiv.classList.remove('file-dragging');
    requests.placeShape(id, canvasPointer.x, canvasPointer.y, loadingIconUuid);
  }
}  

const updateAllImageSizeFields = function(object) {
  // for images and shapes we have to manually set the scale and in feet fields because that's what we use on the backend
  if((object.type == 'image' && object.background != true) || object.shapeType != null) {
    const scaleHeight = parseFloat((object.scaleY * object.height).toFixed(2));
    const scaleWidth = parseFloat((object.scaleX * object.width).toFixed(2));
    const widthInFeet = parseFloat((scaleWidth * canvas.feetPerPixel).toFixed(0));
    const heightInFeet = parseFloat((scaleHeight * canvas.feetPerPixel).toFixed(0));

    object.set({scaleHeight: scaleHeight, scaleWidth: scaleWidth, widthInFeet: widthInFeet, heightInFeet: heightInFeet});
  }
}

const saveObjectChanges = function(options) {
  var object = options.target;
  if(object._objects) {
    // if there are multiple objects, deselect them because their properties 
    // are relative to the group when grouped
    canvas.discardActiveObject();
    canvas.renderAll();
    var objects = object._objects;
  } else {
    updateAllImageSizeFields(object);
    var objects = [object];
  }
  objects.forEach(requests.updateRequest);
}

$( document ).on('campaign_id:set', function(event, campaignId) {
  canvas = window.__board = new fabric.Canvas('board', { fireRightClick: true, stopContextMenu: true });
  canvas.main = $('#main');

  canvas.on('mouse:dblclick', requests.loadObjectShow);
  canvas.on('mouse:down', handleMouseDown);
  canvas.on('object:moving', handleObjectMoving);
  canvas.on('mouse:move', handleMouseMove);
  canvas.on('mouse:over', handleMouseOver);
  canvas.on('mouse:out', handleMouseOut);
  canvas.on('mouse:up', handleMouseUp)
  canvas.on('mouse:wheel', handleMouseWheel)

  canvas.on('object:modified', saveObjectChanges);  
  canvas.on('path:created', function(options) {
    var path = options.path;
    path.set({ uid: uniqueId(), temporary: true, selectable: false, hoverCursor: 'none' })
    canvas.fade_remove_in(path, canvas.highlightTimer);
    requests.createRequest(path);
  });
  canvas.on('drop', function(options) {
    if(!canvas.currentUserIsGm && canvas.restrictVisibility) { return }
    if (Array.from(options.e.dataTransfer.types).includes('Files')) {
      handleBoardNewFileDrop(options);
    } else {
      handleBoardKnownObjectDrop(options);
     }
  });

  canvas.isPinching = false;
  canvas.isDragging = false;
  canvas.contextualMenuOpen = false;
  canvas.highlightTimer = 4000;
  canvas.unlockAttemptTimer = 500;
  canvas.mouseUpPerformed = false;
  canvas.freeDrawingBrush.color = 'purple';
  canvas.freeDrawingBrush.width = 10
  canvas.freeDrawingCursor = 'none'
  canvas.customAttributes = ['uid', 'token', 'scaleX', 'scaleY', 'scaleWidth', 'scaleHeight', 'image_url', 'strokeUniform', 'noScaleCache', 'background', 'wall', 'tokenSize', 'darkvisionRadius', 'lightSource', 'brightLightRadius', 'dimLightRadius', 'temporary', 'pin', 'gmIndicators', 'conditionIndicators', 'showGmCombatIndicator', 'showPlayers', 'transparent', 'pinColor', 'pinSize', 'doorState', 'openDirection', 'pivotSide', 'doorColor', 'doorSize', 'widthInFeet', 'heightInFeet', 'setOpacity', 'lockPosition', 'name', 'shapeType', 'shapeId', 'displayBorder', 'createdAtInteger', 'ruler', 'doNotPersistInDatabase']
  canvas.preserveObjectStacking = true;
  canvas.selection = false;
  canvas.defaultCursor = 'none';
  canvas.campaignId = campaignId;
  canvas.gmLayerVisible = true;
  canvas.tokensVisible = true;
  canvas.assetsVisible = true;
  canvas.visibilityVisible = true;
  canvas.resizeToParent();
  canvas.loadCurrentBoard();

  $( document ).trigger('board:created', canvas);
})
