$( document ).ready(function() {
  $('.video-player-modal').on('show.bs.modal', function (e) {
    let vid = $(this).find('video')[0];
    vid.play();
  })
  $('.video-player-modal').on('hide.bs.modal', function (e) {
    let vid = $(this).find('video')[0];
    vid.pause();
  })

});

