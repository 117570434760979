$( document ).ready(function() {
  const searchParams = new URLSearchParams(window.location.search);
  var modalId = searchParams.get('openModal');
  if(modalId != null) {
    var modalElement = $(`#${modalId}`);
    modalElement.modal('show');
    var vid = modalElement.find('video')[0];
    vid.play();
  }
});
