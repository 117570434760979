import consumer from "../channels/consumer";
import { objectToJSON } from '../custom/board/board_requests'
import { resetConditionIndicatorObjects, resetCombatantIndicatorObject } from "../custom/board/board_indicators";
import { updateIndicatorObjectsPositions } from "../custom/board/board_token_drag";
import { setObjectOpacityAndSelectability } from "../custom/board/board_object_opacity_and_selectability";
import { resetDoorObject, resizeDoorToWall } from "../custom/board/board_doors";
import { updateRulerForZoom } from "../custom/board/board_ruler";

var channel;

const scaleChanged = function(existing, updated) {
  return (updated.scaleWidth != null && updated.scaleWidth != existing.scaleWidth) ||
         (updated.scaleHeight != null && updated.scaleHeight != existing.scaleHeight) ||
         (updated.tokenSize != null && updated.tokenSize != existing.tokenSize)  // this is needed because changing from medium to small doesn't change scaleHeight but we still need to set the scaleX and Y appropiately in that case
}

const hasLightRadius = function(object) {
  return object.dimLightRadius != null || object.brightLightRadius != null
}

$( document ).on('board_id:set', function(event, board) {

  if (channel != null) {
    channel.unsubscribe();
  }
  if (board == null) { return }
  const boardId = board.id

  channel = consumer.subscriptions.create({ channel: "ObjectChannel", board_id: boardId }, {
    received(data) {
      var board = window.__board;

      if (data.client_change_initiated_by_session_id != null && data.client_change_initiated_by_session_id == board.sessionId ) { return }  // skip this update if the update originated from this session

      if(data.type == "create") {
        var newObjectJSON = JSON.parse(data.object);
        board.addBackendObject(newObjectJSON);
        if(newObjectJSON.wall != null) { board.updateVisibility() }
        if(hasLightRadius(newObjectJSON)) { board.updateVisibility() }
      } else if(data.type == "destroy") {
        board.forEachObject(function (object) { 
          if(data.object_ids.includes(object.uid)) {
            board.removeObject(object);
          } 
        });
      } else if(data.type == "update") {
        var updatedObjectJSON = JSON.parse(data.object);
        var found = board.findObject(updatedObjectJSON.uid);

        if (found == null) { return false }
        var foundJSON = objectToJSON(found);
        if (foundJSON != updatedObjectJSON) {
          if(updatedObjectJSON.type == 'pin') { updatedObjectJSON = board.convertServerPinToClient(updatedObjectJSON, board.currentUserIsGm); } // pins need to be converted to front end json 
          if(updatedObjectJSON.ruler == true) { updateRulerForZoom(updatedObjectJSON) }
          found.set(updatedObjectJSON);
          if(updatedObjectJSON.top != null && updatedObjectJSON.left != null) {
            found.setCoords({ top: updatedObjectJSON.top, left: updatedObjectJSON.left }) // fabric requires setCoords to be called, otherwise selectable area is not updated too
          }
          // scale width and height if those properties change (used for scaling background images and tokens) - would be nice to move this to backend but it's hard to calculate the correct scaleX, scaleY there
          if(scaleChanged(foundJSON, updatedObjectJSON)) {
            found.set({scaleX: updatedObjectJSON.scaleWidth / found.width});
            found.set({scaleY: updatedObjectJSON.scaleHeight/ found.height});
            found.setCoords({ top: found.top, left: found.left }); // fabric requires setCoords to be called, otherwise selectable area is not updated too
            resetConditionIndicatorObjects(found);
            resetCombatantIndicatorObject(found);
          }

          // if set opacity changes, we need to explicity call the function to set client side opacity
          if(foundJSON.setOpacity != updatedObjectJSON.setOpacity) {
            setObjectOpacityAndSelectability(found, board);
          }

          // also if set lock position changes, we need to make this call to set the movement and borders
          if(foundJSON.lockPosition != updatedObjectJSON.lockPosition) {
            setObjectOpacityAndSelectability(found, board);
          }

          // set highlight if this is the selected character (the highlight could have been removed by backend)
          if(found.token != null && found.uid == board.selectedCharacterId) {
            board.highlightToken(found);
          }

          if(found.token != null && JSON.stringify(foundJSON.conditionIndicators) != JSON.stringify(updatedObjectJSON.conditionIndicators)){
            resetConditionIndicatorObjects(found);
          }

          if(found.token != null && foundJSON.showGmCombatIndicator != updatedObjectJSON.showGmCombatIndicator){
            resetCombatantIndicatorObject(found);
          }

          if(found.token != null && (foundJSON.top != updatedObjectJSON.top || foundJSON.left != updatedObjectJSON.left)){
            updateIndicatorObjectsPositions(found);
          }
          if(found.wall == true && found.doorObject != null ){
            resizeDoorToWall(found);
          }
          if(found.wall == true && foundJSON.doorState != updatedObjectJSON.doorState){
            resetDoorObject(found);
          }

          // times to update visibility 
          if(found.wall != null || (board.tokenSelected() && found.uid == board.selectedToken().uid)) { board.updateVisibility() }
          if(foundJSON.darkvisionRadius != updatedObjectJSON.darkvisionRadius) { board.updateVisibility() }
          if(foundJSON.dimLightRadius != updatedObjectJSON.dimLightRadius) { board.updateVisibility() }
          if(foundJSON.brightLightRadius != updatedObjectJSON.brightLightRadius) { board.updateVisibility() }
          if(hasLightRadius(foundJSON) && (foundJSON.top != updatedObjectJSON.top || foundJSON.left != updatedObjectJSON.left)) { board.updateVisibility() } // if something with a light moves, update visibility

          if(foundJSON.showPlayers != updatedObjectJSON.showPlayers) {
            setObjectOpacityAndSelectability(found, board);
            if(found.indicatorObjects != null) {
              found.indicatorObjects.forEach(indicatorObject => {
                setObjectOpacityAndSelectability(indicatorObject, board)
              });
            }

          }
          board.renderAll();
        }
      }
    }
  });
})
