var board;
var base = fabric.Canvas.prototype;

$( document ).on('board:created', function(event, b) { board = b; });

base.fade_remove = function(object) {
  object.animate('opacity', '0', {
    duration: 1000,
    onChange: board.renderAll.bind(board),
    onComplete: function() {
      board.remove(object);
    }
  });
}

base.fade_remove_in = function(object, timer) {
  setTimeout(function() {
    board.fade_remove(object)
  }, timer)
}


